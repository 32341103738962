<template>
  <v-container fluid>
    <section class="d-flex justify-space-between align-center">
      <v-btn class="pink" dark @click="getOrders()">Refresh Table</v-btn>
      <span class="d-flex">
        <v-text-field
          label="Search with email or phone"
          class="mx-5"
          placeholder="Placeholder"
          outlined
        ></v-text-field>
        <v-btn> search </v-btn>
      </span>
    </section>
    <v-simple-table class="mt-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Order Id</th>
            <th class="text-left">Edit Order</th>
            <th class="text-left">Is fullfilled</th>
            <th class="text-left">Order by</th>
            <th class="text-left">Date time</th>
            <th class="text-left">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in orders" :key="item.id">
            <td>{{ item.id }}</td>
            <td><Orderdetail :order-id="item.id" /></td>
            <td>
              <v-chip
                class="ma-2"
                :color="getColor(item.fullfilled)"
                label
                text-color="white"
                >{{ item.fullfilled }}
              </v-chip>
            </td>
            <td>{{ item.user_ordered }}</td>
            <td>{{ getDate(item.created_at) }}</td>
            <td>₹ {{ item.price }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center mt-5">
      <v-pagination
        v-model="page"
        @input="onPageChange"
        :length="paginationLength"
        color="rgb(94, 53, 177)"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import Orderdetail from "./Orderdetail.vue";
import URL from "../axios";

export default {
  components: {
    Orderdetail,
  },

  data: () => ({
    orders: null,
    paginationLength: 1,
    page: 1,
    token: localStorage.getItem("admin"),
  }),
  mounted() {
    this.getOrders();
  },
  methods: {
    getDate(strDate) {
      return (
        strDate.substring(0, 10) +
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
        strDate.substring(11, 19)
      );
    },
    getColor(fullfilled) {
      if (fullfilled) {
        return "green darken-1";
      } else {
        return "red lighten-1";
      }
    },
    getOrders() {
      const config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };

      axios.get(URL + "payment/orders-admin/", config).then((response) => {
        (this.orders = response.data.results)(
          (this.paginationLength = response.data.total_pages)
        );
      });
    },
  },
};
</script>
