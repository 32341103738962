<template>
  <v-container>
    <h4 class="my-5">{{ statusResponse.data.feed.description }}</h4>
    <div v-if="statusResponse.status == 200">
      <v-chip class="ma-2" color="green" label text-color="white">
        <v-icon left> mdi-access-point-check</v-icon>
        Servers are running fine
      </v-chip>
    </div>
    <div v-else>
      <v-chip class="ma-2" color="pink" label text-color="white">
        <v-icon left> mdi-access-point-remove </v-icon>
        Seems something is wrong
      </v-chip>
    </div>
    <div class="mt-5" v-for="item in statusResponse.data.items" :key="item">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h4>{{ item.title }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h6>{{ item.pubDate }}</h6>
            <p class="caption mt-2">{{ item.description }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  name: "Demo",
  data() {
    return {
      statusResponse: null,
      panel: [0, 1],
    };
  },
  created() {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://status.aws.amazon.com/rss/ec2-ap-south-1.rss"
      )
      .then((response) => (this.statusResponse = response));
  },
};
</script>