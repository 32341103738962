<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="purple" class="mt-5" small dark v-bind="attrs" v-on="on">
          Add Product
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="black">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Product</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Exit </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-subheader>Add Product</v-subheader>

        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            Create Product
            <small>Admin</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-list three-line subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-form v-model="valid">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="item_name"
                            :rules="formRules"
                            :counter="30"
                            label="Product Name"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="price"
                            :rules="formRules"
                            :counter="30"
                            label="Price"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="mrp_price"
                            :rules="formRules"
                            label="MRP Price"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <h3>Rating:</h3>
                          <v-rating
                            v-model="rating"
                            background-color="purple lighten-3"
                            color="purple"
                            large
                          ></v-rating>
                        </v-col>

                        <v-col cols="12" md="4">
                          <h3>category:</h3>
                          <v-select
                            class="mt-2"
                            dense
                            v-model="category"
                            :items="items"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="manufacturer"
                            :rules="formRules"
                            label="Manufacturer"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="dimensions"
                            :rules="formRules"
                            label="Dimension details"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-textarea
                            v-model="description"
                            outlined
                            :rules="formRules"
                            required
                            label="Product Description"
                          ></v-textarea>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-switch
                            v-model="Is_available"
                            label="Is Available ?"
                            color="success"
                          ></v-switch>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="warranty_period"
                            :rules="formRules"
                            label="Warranty Period"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-textarea
                            v-model="warranty_description"
                            outlined
                            :rules="formRules"
                            required
                            label="Warranty Description"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-btn
                        class="mt-9 white--text"
                        :disabled="!valid"
                        @click="addProduct()"
                        :loading="createbutton"
                        color="black"
                        >Create Product</v-btn
                      >
                      <!-- <v-btn color="primary" class="mt-9 ml-2" @click="e6 = 2"
                        >Next</v-btn
                      > -->
                    </v-container>
                  </v-form>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-btn text> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2">
            Upload images
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-file-input
              accept="image/*"
              label="Upload Image"
              prepend-icon="mdi-camera"
              v-model="imageData"
            ></v-file-input>

            <p>Try to upload images with lesser size for speed. Max(6MB)</p>

            <v-btn
              color="blue"
              @click="uploadImages()"
              :loading="uploadbutton"
              dark
            >
              upload
            </v-btn>
            <v-btn class="mx-2" @click="e6 = 1">Add Another Product</v-btn>
          </v-stepper-content>
        </v-stepper>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import URL from "../axios";

export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      valid: false,
      e6: 1,
      formRules: [(v) => !!v || "Item is required"],
      token: localStorage.getItem("admin"),
      new_item_id: "",

      item_name: "",
      price: "",
      mrp_price: "",
      rating: 4,
      category: "",
      manufacturer: "",
      dimensions: "",
      description: "",
      Is_available: true,
      warranty_period: "",
      warranty_description: "",

      uploadbutton: false,
      createbutton: false,
      imageData: null,

      items: [
        "Helmets",
        "Riding gears",
        "Luggage",
        "Accessories",
        "Protection",
        "Footwears",
      ],
    };
  },

  methods: {
    addProduct() {
      this.createbutton = true;

      const data = {
        item_name: this.item_name,
        price: this.price,
        mrp_price: this.mrp_price,
        rating: this.rating,
        category: this.category,
        manufacturer: this.manufacturer,
        dimensions: this.dimensions,
        description: this.description,
        Is_available: this.Is_available,
        warranty_period: this.warranty_period,
        warranty_description: this.warranty_description,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };

      axios
        .post(URL + "api/v1/products-admin/", data, config)
        .then((response) => {
          this.new_item_id = response.data.id;
          this.createbutton = false;
          this.e6 = 2;
        })
        .catch((err) => {
          console.log(err);
          this.createbutton = false;
        });
    },

    uploadImages() {
      this.uploadbutton = true;
      let formData = new FormData();
      formData.append("image", this.imageData);
      formData.append("item_name", this.new_item_id);

      if (this.imageData !== null) {
        const config = {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-Type": "multipart/form-data",
          },
        };

        axios
          .post(URL + "api/v1/products-admin-images/", formData, config)
          .then((response) => {
            console.log(response);
            this.uploadbutton = false;
            alert("done uploading image");
          })
          .catch((err) => {
            console.log(err);
            this.uploadbutton = false;
          });
      } else {
        this.uploadbutton = false;
        alert("please select an image");
      }
    },
  },
};
</script>
