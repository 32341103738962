<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-5">
        <productdetail />
      </v-col>

      <v-col class="mt-5">  
        <v-text-field dense label="Enter term" solo> </v-text-field>
      </v-col>
      <v-col class="mt-5">
        <v-btn small color="white">Search</v-btn> (comming soon)
      </v-col>

      <v-col class="mt-5">
        <v-btn class="pink" small dark @click="getProducts()"
          >Refresh Page</v-btn
        >
      </v-col>
    </v-row>

    <v-card
      class="m-0 p-0 d-flex justify-space-around ma-6 flex-wrap mt-2"
      color="dark"
    >
      <span v-for="product in products" :key="product.id">
        <div>
          <span v-if="product.thumbnails[0]">
            <img
              :src="product.thumbnails[0].image"
              height="120"
              width="120"
              class="mt-5"
            />
          </span>
          <span v-else>
            <v-img
              height="120"
              width="120"
              src="https://raw.githubusercontent.com/Adarshreddyash/background-images-icons/master/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
            ></v-img>
          </span>
        </div>

        <p class="text-center font-weight-bold truncate-2">
          {{ product.item_name }}
        </p>
        <span class="d-flex flex-row justify-center">
          <p class="text-center font-weight-medium">₹{{ product.price }}</p>
          <p class="text-center ml-2 mt-2 text-decoration-line-through caption">
            ₹{{ product.mrp_price }}
          </p>
        </span>

        <Editproduct :pid="product.id" />
      </span>
    </v-card>
    <v-pagination
      v-model="page"
      :length="paginationLength"
      @input="onPageChange"
      circle
    ></v-pagination>
  </v-container>
</template>

<script>
import axios from "axios";
import productdetail from "./productdetail";
import Editproduct from "./Editproduct";
import URL from "../axios";

export default {
  data: () => ({
    products: [],
    paginationLength: 1,
    overlay: false,
    page: 1,
  }),
  components: {
    productdetail,
    Editproduct,
  },
  mounted() {
    this.getProducts(this.page);
  },
  methods: {
    getProducts(page) {
      axios.get(URL + "api/v1/products/?page=" + page).then((response) => {
        (this.products = response.data.results)(
          (this.paginationLength = response.data.total_pages)
        );
      });
    },
    onPageChange() {
      this.getProducts(this.page);
    },
  },

  watch: {},
};
</script>
<style type="text/css">
.truncate-2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
