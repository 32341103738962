<template>
  <v-app>
    <v-card elevation="0" class="rounded-0">
      <v-toolbar color="black" dark flat v-show="isloggedin">
        <v-toolbar-title>
          <h3>Napduck</h3>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn @click="doLogout()" class="purple mx-5">Log out</v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="black"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-main v-if="!isloggedin">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on"> Login </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Login</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      label="Email*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Password*"
                      type="password"
                      v-model="password"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doLogin()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>

      <v-main v-else>
        <v-tabs-items v-model="tab" class="deep-purple lighten-5">
          <v-tab-item>
            <v-layout>
              <OrdersRecieved />
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout fill-height>
              <h3>Comming soon..</h3>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout>
              <WebsiteFrame />
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout>
              <ServiceHealth />
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout>
              <contactdev />
            </v-layout>
          </v-tab-item>
        </v-tabs-items>
      </v-main>
    </v-card>
  </v-app>
</template>

<script>
import axios from "axios";

import OrdersRecieved from "./components/OrdersRecieved.vue";
import Contactdev from "./components/contactdev.vue";
import ServiceHealth from "./components/service.vue";
import WebsiteFrame from "./components/WebsiteFrame.vue";
import URL from "./axios";

export default {
  name: "App",

  components: {
    OrdersRecieved,
    Contactdev,
    ServiceHealth,
    WebsiteFrame,
  },

  data: () => ({
    tab: null,
    dialog: localStorage.getItem("admin") === "true" ? true : false,
    email: "",
    password: "",
    items: [
      "ORDERS",
      "DRAFT ORDERS",
      "Products",
      "AWS Service Health",
      "Contact Dev",
    ],
    isloggedin: localStorage.getItem("admin") === "true" ? true : false,
    //
  }),

  methods: {
    doLogin() {
      const data = {
        userid: this.email,
        password: this.password,
      };
      axios
        .post(URL + "accounts/llogin", data)
        .then((response) => {
          // alert(response.data.key);
          localStorage.setItem("admin", response.data.access);
          this.isloggedin = true;
        })
        .catch((error) => {
          alert(error.response.data.non_field_errors[0]);
        });
    },
    doLogout() {
      localStorage.removeItem("admin");
      history.go();
    },
  },
};
</script>
<style scoped>
.theme--light.v-application {
  background: #ede7f6;
  color: rgba(0, 0, 0, 0.87);
}
</style>
