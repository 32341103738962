<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="deep-purple darken-1" dark v-bind="attrs" v-on="on">
          Edit
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="black">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Product Detail</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>You are editing this Order</v-subheader>
        </v-list>

        <div class="ma-8 d-flex flex-row flex-wrap">
          <span class="">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Order id :</v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-black black--text pt-2"
                  >{{ orderId }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> TOTAL PRICE :</v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-black black--text pt-2"
                  >{{ orderdata.price }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> ORDER PAYMENT ID :</v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-black black--text pt-2"
                  >{{ orderdata.order_payment_id }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> ORDERED BY :</v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-black black--text pt-2"
                  >{{ orderdata.user_ordered }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> ORDER ADDRESS :</v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-black black--text pt-2"
                  >{{ orderdata.Address }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> USER PHONE :</v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-black black--text pt-2"
                  >{{ orderdata.Phone }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </span>

          <span class="ma-2">
            <h6>Order Message:</h6>
            <v-text-field
              v-model="ordermessage"
              solo
              :label="ordermessage"
              clearable
            ></v-text-field>

            <h6>Is Fullfilled</h6>
            <v-checkbox v-model="checkbox" label="done"></v-checkbox>

            <section>
              <h6>Is delivered</h6>
              <v-checkbox v-model="is_delivered" label="done"></v-checkbox>
            </section>

            <section>
              <h6>Expected Date</h6>
              <v-text-field
                v-model="expected_date"
                solo
                type="datetime-local"
                clearable
              ></v-text-field>
            </section>

            <v-btn class="deep-purple darken-1" dark @click="putOrder()"
              >Update</v-btn
            >
          </span>
          <h6>{{ updateMessage }}</h6>
        </div>
        <v-divider class="my-5"></v-divider>
        <div class="d-flex flex-row flex-wrap ma-8 pa-2">
          <span v-for="item in orderdata.items_ordered" :key="item.id">
            <p>
              <span class="font-weight-black">Item id :</span> {{ item.id }}
            </p>
            <p>
              <span class="font-weight-black">item name :</span>
              {{ item.item_name }}
            </p>
            <p>
              <span class="font-weight-black"> Item quantity: </span>
              {{ item.quantity }}
            </p>
            <p>
              <span class="font-weight-black">item price :</span>
              {{ item.price }}
            </p>
            <p>
              <span class="font-weight-black">Mrp price :</span>
              {{ item.mrp_price }}
            </p>
            <p>
              <span class="font-weight-black">item available :</span>
              {{ item.Is_available }}
            </p>
            <v-divider class="my-5"></v-divider>
          </span>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import URL from "../axios";

export default {
  props: ["orderId"],

  data() {
    return {
      dialog: false,

      orderdata: null,
      checkbox: "",
      is_delivered: "",
      expected_date: "",
      token: localStorage.getItem("admin"),

      ordermessage: "",
      updateMessage: "",
    };
  },

  mounted() {
    const config = {
      headers: {
        Authorization: "Bearer " + this.token,
      },
    };

    axios
      .get(URL + "payment/orders-admin/" + this.orderId + "/", config)
      .then((response) => {
        this.orderdata = response.data;
        this.checkbox = response.data.fullfilled;
        this.is_delivered = response.data.is_delivered;
        this.ordermessage = response.data.message;
        this.expected_date = response.data.expected_date;
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    putOrder() {
      const data = {
        message: this.ordermessage,
        fullfilled: this.checkbox,
        is_delivered: this.is_delivered,
        expected_date: this.expected_date,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };

      axios
        .patch(URL + "payment/orders-admin/" + this.orderId + "/", data, config)
        .then((response) => console.log(response), alert("done updating"))
        .catch((err) => {
          console.log(err);
          alert("something went wrong ! contact dev");
        });
    },
  },
};
</script>