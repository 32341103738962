<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="black" class="mt-5" x-small dark v-bind="attrs" v-on="on">
          Edit
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="black">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Product</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Exit </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-subheader>Edit Product</v-subheader>

        <v-row class="ma-5">
          <v-col
            v-for="(item, n) in product.thumbnails"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-img
                  :src="item.image"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#036358">
                      <v-btn color="red" @click="deleteImage(item.id)">
                        <v-icon>mdi-image-remove</v-icon> Delete
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </template>
            </v-hover>
          </v-col>
          <v-col>
            <v-file-input
              accept="image/*"
              label="Upload Image"
              prepend-icon="mdi-camera"
              v-model="new_image"
            ></v-file-input>
            <p>Try to upload images with lesser size for speed. Max(6MB)</p>
            <v-btn
              class="mx-2"
              fab
              dark
              :loading="uploadbutton"
              large
              color="blue"
              @click="addImage()"
            >
              <v-icon dark> mdi-image-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-divider></v-divider>

              <v-form v-model="valid" class="mt-9">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item_name"
                        :rules="formRules"
                        :counter="300"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="price"
                        :rules="formRules"
                        :counter="30"
                        label="Price"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="mrp_price"
                        :rules="formRules"
                        label="MRP Price"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <h3>Rating:</h3>
                      <v-rating
                        v-model="rating"
                        background-color="purple lighten-3"
                        color="purple"
                        large
                      ></v-rating>
                    </v-col>

                    <v-col cols="12" md="4">
                      <h3>category:</h3>
                      <v-select
                        class="mt-2"
                        dense
                        v-model="category"
                        :items="items"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="manufacturer"
                        :rules="formRules"
                        label="Manufacturer"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="dimensions"
                        :rules="formRules"
                        label="Dimension details"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-textarea
                        v-model="description"
                        outlined
                        :rules="formRules"
                        required
                        label="Product Description"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-switch
                        v-model="Is_available"
                        label="Is Available ?"
                        color="success"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-btn
                    class="mt-9 white--text"
                    @click="updateProduct()"
                    :loading="createbutton"
                    color="black"
                    >Update Product</v-btn
                  >
                </v-container>
              </v-form>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn class="ma-15" color="red" dark @click="deleteProduct()">
          <v-icon>mdi-delete</v-icon>
          Delete Product</v-btn
        >

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import URL from "../axios";

export default {
  props: ["pid"],

  data() {
    return {
      dialog: false,
      valid: false,
      product: {},
      formRules: [(v) => !!v || "Item is required"],
      token: localStorage.getItem("admin"),

      item_name: "",
      price: "",
      mrp_price: "",
      rating: 4,
      category: "",
      manufacturer: "",
      dimensions: "",
      description: "",
      Is_available: "",

      createbutton: false,
      uploadbutton: false,
      new_image: null,

      items: [
        "jewellery items",
        "idols",
        "gifting items",
        "dresses",
        "yantras",
      ],
    };
  },

  mounted() {
    this.getProduct();
  },

  methods: {
    updateProduct() {
      this.createbutton = true;

      const data = {
        item_name: this.item_name,
        price: this.price,
        mrp_price: this.mrp_price,
        rating: this.rating,
        category: this.category,
        manufacturer: this.manufacturer,
        dimensions: this.dimensions,
        description: this.description,
        Is_available: this.Is_available,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };

      axios
        .put(URL + "api/v1/products-admin/" + this.pid + "/", data, config)
        .then((response) => {
          this.new_item_id = response.data.id;
          this.createbutton = false;
          alert("Product Updated");
        })
        .catch((err) => {
          console.log(err);
          alert("Error");
          this.createbutton = false;
        });
    },
    getProduct() {
      const config2 = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      console.log(this.pid);
      axios
        .get(URL + "api/v1/products-admin/" + this.pid + "/", config2)
        .then((response) => {
          this.product = response.data;
          this.item_name = this.product.item_name;
          this.price = this.product.price;
          this.mrp_price = this.product.mrp_price;
          this.rating = this.product.rating;
          this.category = this.product.category;
          this.brand = this.product.brand;
          this.brand_model = this.product.brand_model;
          this.manufacturer = this.product.manufacturer;
          this.dimensions = this.product.dimensions;
          this.description = this.product.description;
          this.Is_available = this.product.Is_available;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteProduct() {
      const config3 = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      axios
        .delete(URL + "api/v1/products-admin/" + this.pid + "/", config3)
        .then((response) => {
          alert("Product deleted Successfully");
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          alert("Something went wrong");
        });
    },
    addImage() {
      this.uploadbutton = true;
      let formData = new FormData();
      formData.append("image", this.new_image);
      formData.append("item_name", this.pid);

      const config3 = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };

      axios
        .post(URL + "api/v1/products-admin-images/", formData, config3)
        .then((response) => {
          alert("Image uploaded Successfully");
          console.log(response);
          this.uploadbutton = false;
        })
        .catch((err) => {
          console.log(err);
          this.uploadbutton = false;
        });
    },
    deleteImage(id) {
      const config3 = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      axios
        .delete(URL + "api/v1/products-admin-images/" + id + "/", config3)
        .then((response) => {
          alert("Image deleted Successfully");

          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>